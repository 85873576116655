.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: '\A0';
}
.flag-icon.flag-icon-squared {
  width: 1em;
}
.flag-icon-ad {
  background-image: url(/static/media/ad.8c821c9a.svg);
}
.flag-icon-ad.flag-icon-squared {
  background-image: url(/static/media/ad.b2946bd8.svg);
}
.flag-icon-ae {
  background-image: url(/static/media/ae.42d71570.svg);
}
.flag-icon-ae.flag-icon-squared {
  background-image: url(/static/media/ae.b62c8ca6.svg);
}
.flag-icon-af {
  background-image: url(/static/media/af.80deffc5.svg);
}
.flag-icon-af.flag-icon-squared {
  background-image: url(/static/media/af.7773ac25.svg);
}
.flag-icon-ag {
  background-image: url(/static/media/ag.ea764fd5.svg);
}
.flag-icon-ag.flag-icon-squared {
  background-image: url(/static/media/ag.c8adbf23.svg);
}
.flag-icon-ai {
  background-image: url(/static/media/ai.ddacf37b.svg);
}
.flag-icon-ai.flag-icon-squared {
  background-image: url(/static/media/ai.7e4ee030.svg);
}
.flag-icon-al {
  background-image: url(/static/media/al.ee45ea7b.svg);
}
.flag-icon-al.flag-icon-squared {
  background-image: url(/static/media/al.60765067.svg);
}
.flag-icon-am {
  background-image: url(/static/media/am.6a9de6f2.svg);
}
.flag-icon-am.flag-icon-squared {
  background-image: url(/static/media/am.ea6051ba.svg);
}
.flag-icon-ao {
  background-image: url(/static/media/ao.52753def.svg);
}
.flag-icon-ao.flag-icon-squared {
  background-image: url(/static/media/ao.11f61ca5.svg);
}
.flag-icon-aq {
  background-image: url(/static/media/aq.d9662482.svg);
}
.flag-icon-aq.flag-icon-squared {
  background-image: url(/static/media/aq.6f1f242c.svg);
}
.flag-icon-ar {
  background-image: url(/static/media/ar.83514108.svg);
}
.flag-icon-ar.flag-icon-squared {
  background-image: url(/static/media/ar.33e866d1.svg);
}
.flag-icon-as {
  background-image: url(/static/media/as.ff1f9cd7.svg);
}
.flag-icon-as.flag-icon-squared {
  background-image: url(/static/media/as.1ba289b9.svg);
}
.flag-icon-at {
  background-image: url(/static/media/at.8dc68995.svg);
}
.flag-icon-at.flag-icon-squared {
  background-image: url(/static/media/at.27ec5a7f.svg);
}
.flag-icon-au {
  background-image: url(/static/media/au.10c90118.svg);
}
.flag-icon-au.flag-icon-squared {
  background-image: url(/static/media/au.85c0689d.svg);
}
.flag-icon-aw {
  background-image: url(/static/media/aw.59ef0251.svg);
}
.flag-icon-aw.flag-icon-squared {
  background-image: url(/static/media/aw.513ef5fd.svg);
}
.flag-icon-ax {
  background-image: url(/static/media/ax.62ea912b.svg);
}
.flag-icon-ax.flag-icon-squared {
  background-image: url(/static/media/ax.1345aec8.svg);
}
.flag-icon-az {
  background-image: url(/static/media/az.403e9b3f.svg);
}
.flag-icon-az.flag-icon-squared {
  background-image: url(/static/media/az.ee61daa1.svg);
}
.flag-icon-ba {
  background-image: url(/static/media/ba.f7530d42.svg);
}
.flag-icon-ba.flag-icon-squared {
  background-image: url(/static/media/ba.8d9a86c2.svg);
}
.flag-icon-bb {
  background-image: url(/static/media/bb.89161f14.svg);
}
.flag-icon-bb.flag-icon-squared {
  background-image: url(/static/media/bb.6eab019e.svg);
}
.flag-icon-bd {
  background-image: url(/static/media/bd.e99cb11f.svg);
}
.flag-icon-bd.flag-icon-squared {
  background-image: url(/static/media/bd.9a6cf432.svg);
}
.flag-icon-be {
  background-image: url(/static/media/be.6cac0379.svg);
}
.flag-icon-be.flag-icon-squared {
  background-image: url(/static/media/be.6fc013c9.svg);
}
.flag-icon-bf {
  background-image: url(/static/media/bf.4755cc0e.svg);
}
.flag-icon-bf.flag-icon-squared {
  background-image: url(/static/media/bf.7194e240.svg);
}
.flag-icon-bg {
  background-image: url(/static/media/bg.5a32d80f.svg);
}
.flag-icon-bg.flag-icon-squared {
  background-image: url(/static/media/bg.47c70d31.svg);
}
.flag-icon-bh {
  background-image: url(/static/media/bh.a4ca6115.svg);
}
.flag-icon-bh.flag-icon-squared {
  background-image: url(/static/media/bh.c3102980.svg);
}
.flag-icon-bi {
  background-image: url(/static/media/bi.36146887.svg);
}
.flag-icon-bi.flag-icon-squared {
  background-image: url(/static/media/bi.2d210422.svg);
}
.flag-icon-bj {
  background-image: url(/static/media/bj.7f6166b5.svg);
}
.flag-icon-bj.flag-icon-squared {
  background-image: url(/static/media/bj.ea7e4090.svg);
}
.flag-icon-bl {
  background-image: url(/static/media/bl.ebfec527.svg);
}
.flag-icon-bl.flag-icon-squared {
  background-image: url(/static/media/bl.8028dd11.svg);
}
.flag-icon-bm {
  background-image: url(/static/media/bm.1a85b945.svg);
}
.flag-icon-bm.flag-icon-squared {
  background-image: url(/static/media/bm.5d6517e1.svg);
}
.flag-icon-bn {
  background-image: url(/static/media/bn.d5a2d918.svg);
}
.flag-icon-bn.flag-icon-squared {
  background-image: url(/static/media/bn.62e7e8c1.svg);
}
.flag-icon-bo {
  background-image: url(/static/media/bo.f983aef7.svg);
}
.flag-icon-bo.flag-icon-squared {
  background-image: url(/static/media/bo.6e8b7e0f.svg);
}
.flag-icon-bq {
  background-image: url(/static/media/bq.63fa6eef.svg);
}
.flag-icon-bq.flag-icon-squared {
  background-image: url(/static/media/bq.a217e580.svg);
}
.flag-icon-br {
  background-image: url(/static/media/br.155348d0.svg);
}
.flag-icon-br.flag-icon-squared {
  background-image: url(/static/media/br.8bcede61.svg);
}
.flag-icon-bs {
  background-image: url(/static/media/bs.f71baf48.svg);
}
.flag-icon-bs.flag-icon-squared {
  background-image: url(/static/media/bs.76c01983.svg);
}
.flag-icon-bt {
  background-image: url(/static/media/bt.d35b5dd2.svg);
}
.flag-icon-bt.flag-icon-squared {
  background-image: url(/static/media/bt.552a870b.svg);
}
.flag-icon-bv {
  background-image: url(/static/media/bv.4a3cbeed.svg);
}
.flag-icon-bv.flag-icon-squared {
  background-image: url(/static/media/bv.83e56e13.svg);
}
.flag-icon-bw {
  background-image: url(/static/media/bw.8b493920.svg);
}
.flag-icon-bw.flag-icon-squared {
  background-image: url(/static/media/bw.fee2051b.svg);
}
.flag-icon-by {
  background-image: url(/static/media/by.a521eebe.svg);
}
.flag-icon-by.flag-icon-squared {
  background-image: url(/static/media/by.12097dbd.svg);
}
.flag-icon-bz {
  background-image: url(/static/media/bz.10078a02.svg);
}
.flag-icon-bz.flag-icon-squared {
  background-image: url(/static/media/bz.49323c5b.svg);
}
.flag-icon-ca {
  background-image: url(/static/media/ca.ad152714.svg);
}
.flag-icon-ca.flag-icon-squared {
  background-image: url(/static/media/ca.36a398cb.svg);
}
.flag-icon-cc {
  background-image: url(/static/media/cc.78a55c27.svg);
}
.flag-icon-cc.flag-icon-squared {
  background-image: url(/static/media/cc.91b7acfa.svg);
}
.flag-icon-cd {
  background-image: url(/static/media/cd.4346647b.svg);
}
.flag-icon-cd.flag-icon-squared {
  background-image: url(/static/media/cd.e0101fc8.svg);
}
.flag-icon-cf {
  background-image: url(/static/media/cf.99c6b22d.svg);
}
.flag-icon-cf.flag-icon-squared {
  background-image: url(/static/media/cf.cbd95957.svg);
}
.flag-icon-cg {
  background-image: url(/static/media/cg.c1bacfa7.svg);
}
.flag-icon-cg.flag-icon-squared {
  background-image: url(/static/media/cg.c0adcefa.svg);
}
.flag-icon-ch {
  background-image: url(/static/media/ch.0d84f2af.svg);
}
.flag-icon-ch.flag-icon-squared {
  background-image: url(/static/media/ch.81904d38.svg);
}
.flag-icon-ci {
  background-image: url(/static/media/ci.71b3f6b8.svg);
}
.flag-icon-ci.flag-icon-squared {
  background-image: url(/static/media/ci.4914b405.svg);
}
.flag-icon-ck {
  background-image: url(/static/media/ck.bf16b1e4.svg);
}
.flag-icon-ck.flag-icon-squared {
  background-image: url(/static/media/ck.a734ef78.svg);
}
.flag-icon-cl {
  background-image: url(/static/media/cl.1cc139d8.svg);
}
.flag-icon-cl.flag-icon-squared {
  background-image: url(/static/media/cl.53ca595c.svg);
}
.flag-icon-cm {
  background-image: url(/static/media/cm.b2723805.svg);
}
.flag-icon-cm.flag-icon-squared {
  background-image: url(/static/media/cm.3e2c5b4b.svg);
}
.flag-icon-cn {
  background-image: url(/static/media/cn.5c573ebe.svg);
}
.flag-icon-cn.flag-icon-squared {
  background-image: url(/static/media/cn.23852b0c.svg);
}
.flag-icon-co {
  background-image: url(/static/media/co.eefa6c2c.svg);
}
.flag-icon-co.flag-icon-squared {
  background-image: url(/static/media/co.2d279930.svg);
}
.flag-icon-cr {
  background-image: url(/static/media/cr.8f9e3dcc.svg);
}
.flag-icon-cr.flag-icon-squared {
  background-image: url(/static/media/cr.a7d3b312.svg);
}
.flag-icon-cu {
  background-image: url(/static/media/cu.f28cba69.svg);
}
.flag-icon-cu.flag-icon-squared {
  background-image: url(/static/media/cu.2e0e65e6.svg);
}
.flag-icon-cv {
  background-image: url(/static/media/cv.1bd74d97.svg);
}
.flag-icon-cv.flag-icon-squared {
  background-image: url(/static/media/cv.825501fd.svg);
}
.flag-icon-cw {
  background-image: url(/static/media/cw.5a3dad6f.svg);
}
.flag-icon-cw.flag-icon-squared {
  background-image: url(/static/media/cw.d0e9d598.svg);
}
.flag-icon-cx {
  background-image: url(/static/media/cx.bbaba316.svg);
}
.flag-icon-cx.flag-icon-squared {
  background-image: url(/static/media/cx.2eee9616.svg);
}
.flag-icon-cy {
  background-image: url(/static/media/cy.b3ef7d78.svg);
}
.flag-icon-cy.flag-icon-squared {
  background-image: url(/static/media/cy.6d82b6c7.svg);
}
.flag-icon-cz {
  background-image: url(/static/media/cz.859f18a5.svg);
}
.flag-icon-cz.flag-icon-squared {
  background-image: url(/static/media/cz.8ffb243f.svg);
}
.flag-icon-de {
  background-image: url(/static/media/de.78feb91b.svg);
}
.flag-icon-de.flag-icon-squared {
  background-image: url(/static/media/de.4e64ea42.svg);
}
.flag-icon-dj {
  background-image: url(/static/media/dj.583f0c78.svg);
}
.flag-icon-dj.flag-icon-squared {
  background-image: url(/static/media/dj.1b0a1070.svg);
}
.flag-icon-dk {
  background-image: url(/static/media/dk.33bba71c.svg);
}
.flag-icon-dk.flag-icon-squared {
  background-image: url(/static/media/dk.079b8347.svg);
}
.flag-icon-dm {
  background-image: url(/static/media/dm.bf34913a.svg);
}
.flag-icon-dm.flag-icon-squared {
  background-image: url(/static/media/dm.4cb659a7.svg);
}
.flag-icon-do {
  background-image: url(/static/media/do.638e03c0.svg);
}
.flag-icon-do.flag-icon-squared {
  background-image: url(/static/media/do.1ace9613.svg);
}
.flag-icon-dz {
  background-image: url(/static/media/dz.b37c4fcf.svg);
}
.flag-icon-dz.flag-icon-squared {
  background-image: url(/static/media/dz.e8688062.svg);
}
.flag-icon-ec {
  background-image: url(/static/media/ec.d8b5c15a.svg);
}
.flag-icon-ec.flag-icon-squared {
  background-image: url(/static/media/ec.89c78367.svg);
}
.flag-icon-ee {
  background-image: url(/static/media/ee.2b95c4e2.svg);
}
.flag-icon-ee.flag-icon-squared {
  background-image: url(/static/media/ee.fee3c743.svg);
}
.flag-icon-eg {
  background-image: url(/static/media/eg.34cb0b1f.svg);
}
.flag-icon-eg.flag-icon-squared {
  background-image: url(/static/media/eg.f9ab7974.svg);
}
.flag-icon-eh {
  background-image: url(/static/media/eh.edba217f.svg);
}
.flag-icon-eh.flag-icon-squared {
  background-image: url(/static/media/eh.ea931ccc.svg);
}
.flag-icon-er {
  background-image: url(/static/media/er.075f7d29.svg);
}
.flag-icon-er.flag-icon-squared {
  background-image: url(/static/media/er.7b2d3410.svg);
}
.flag-icon-es {
  background-image: url(/static/media/es.426a1551.svg);
}
.flag-icon-es.flag-icon-squared {
  background-image: url(/static/media/es.dae16f90.svg);
}
.flag-icon-et {
  background-image: url(/static/media/et.1df16ee8.svg);
}
.flag-icon-et.flag-icon-squared {
  background-image: url(/static/media/et.26d0337e.svg);
}
.flag-icon-fi {
  background-image: url(/static/media/fi.4dc3dbe1.svg);
}
.flag-icon-fi.flag-icon-squared {
  background-image: url(/static/media/fi.2873b463.svg);
}
.flag-icon-fj {
  background-image: url(/static/media/fj.94bf58dd.svg);
}
.flag-icon-fj.flag-icon-squared {
  background-image: url(/static/media/fj.0be05cf9.svg);
}
.flag-icon-fk {
  background-image: url(/static/media/fk.6bfcb429.svg);
}
.flag-icon-fk.flag-icon-squared {
  background-image: url(/static/media/fk.1c724f0a.svg);
}
.flag-icon-fm {
  background-image: url(/static/media/fm.2b9aa7bf.svg);
}
.flag-icon-fm.flag-icon-squared {
  background-image: url(/static/media/fm.d3becf4c.svg);
}
.flag-icon-fo {
  background-image: url(/static/media/fo.ccfdd328.svg);
}
.flag-icon-fo.flag-icon-squared {
  background-image: url(/static/media/fo.fcae5b2a.svg);
}
.flag-icon-fr {
  background-image: url(/static/media/fr.61406a35.svg);
}
.flag-icon-fr.flag-icon-squared {
  background-image: url(/static/media/fr.923011d9.svg);
}
.flag-icon-ga {
  background-image: url(/static/media/ga.f64e29ed.svg);
}
.flag-icon-ga.flag-icon-squared {
  background-image: url(/static/media/ga.1aa26b2b.svg);
}
.flag-icon-gb {
  background-image: url(/static/media/gb.fcfef678.svg);
}
.flag-icon-gb.flag-icon-squared {
  background-image: url(/static/media/gb.521ff917.svg);
}
.flag-icon-gd {
  background-image: url(/static/media/gd.67cf8359.svg);
}
.flag-icon-gd.flag-icon-squared {
  background-image: url(/static/media/gd.5a9b5d94.svg);
}
.flag-icon-ge {
  background-image: url(/static/media/ge.a397fa63.svg);
}
.flag-icon-ge.flag-icon-squared {
  background-image: url(/static/media/ge.36380dcf.svg);
}
.flag-icon-gf {
  background-image: url(/static/media/gf.ac1924e7.svg);
}
.flag-icon-gf.flag-icon-squared {
  background-image: url(/static/media/gf.c6de102f.svg);
}
.flag-icon-gg {
  background-image: url(/static/media/gg.453d95c7.svg);
}
.flag-icon-gg.flag-icon-squared {
  background-image: url(/static/media/gg.ec290fee.svg);
}
.flag-icon-gh {
  background-image: url(/static/media/gh.b1a97938.svg);
}
.flag-icon-gh.flag-icon-squared {
  background-image: url(/static/media/gh.9e338876.svg);
}
.flag-icon-gi {
  background-image: url(/static/media/gi.944d9dba.svg);
}
.flag-icon-gi.flag-icon-squared {
  background-image: url(/static/media/gi.0824d9db.svg);
}
.flag-icon-gl {
  background-image: url(/static/media/gl.c6090a99.svg);
}
.flag-icon-gl.flag-icon-squared {
  background-image: url(/static/media/gl.05073911.svg);
}
.flag-icon-gm {
  background-image: url(/static/media/gm.21b07ec6.svg);
}
.flag-icon-gm.flag-icon-squared {
  background-image: url(/static/media/gm.301e054b.svg);
}
.flag-icon-gn {
  background-image: url(/static/media/gn.f1d6c153.svg);
}
.flag-icon-gn.flag-icon-squared {
  background-image: url(/static/media/gn.b95b7a54.svg);
}
.flag-icon-gp {
  background-image: url(/static/media/gp.fb3de107.svg);
}
.flag-icon-gp.flag-icon-squared {
  background-image: url(/static/media/gp.6419ef0d.svg);
}
.flag-icon-gq {
  background-image: url(/static/media/gq.52b4ac09.svg);
}
.flag-icon-gq.flag-icon-squared {
  background-image: url(/static/media/gq.e3876a88.svg);
}
.flag-icon-gr {
  background-image: url(/static/media/gr.32ae0eae.svg);
}
.flag-icon-gr.flag-icon-squared {
  background-image: url(/static/media/gr.4a4d3e25.svg);
}
.flag-icon-gs {
  background-image: url(/static/media/gs.07175335.svg);
}
.flag-icon-gs.flag-icon-squared {
  background-image: url(/static/media/gs.91ad8e83.svg);
}
.flag-icon-gt {
  background-image: url(/static/media/gt.c04a0caa.svg);
}
.flag-icon-gt.flag-icon-squared {
  background-image: url(/static/media/gt.712ebee0.svg);
}
.flag-icon-gu {
  background-image: url(/static/media/gu.a019590d.svg);
}
.flag-icon-gu.flag-icon-squared {
  background-image: url(/static/media/gu.ee47691a.svg);
}
.flag-icon-gw {
  background-image: url(/static/media/gw.6241752e.svg);
}
.flag-icon-gw.flag-icon-squared {
  background-image: url(/static/media/gw.be7d9fe1.svg);
}
.flag-icon-gy {
  background-image: url(/static/media/gy.6a64d2f4.svg);
}
.flag-icon-gy.flag-icon-squared {
  background-image: url(/static/media/gy.c908281a.svg);
}
.flag-icon-hk {
  background-image: url(/static/media/hk.72724f7f.svg);
}
.flag-icon-hk.flag-icon-squared {
  background-image: url(/static/media/hk.9fe81650.svg);
}
.flag-icon-hm {
  background-image: url(/static/media/hm.fb925e7c.svg);
}
.flag-icon-hm.flag-icon-squared {
  background-image: url(/static/media/hm.e29f565b.svg);
}
.flag-icon-hn {
  background-image: url(/static/media/hn.b661b74a.svg);
}
.flag-icon-hn.flag-icon-squared {
  background-image: url(/static/media/hn.0f3d09a0.svg);
}
.flag-icon-hr {
  background-image: url(/static/media/hr.9b7fd1f4.svg);
}
.flag-icon-hr.flag-icon-squared {
  background-image: url(/static/media/hr.a7f61bab.svg);
}
.flag-icon-ht {
  background-image: url(/static/media/ht.800c2d43.svg);
}
.flag-icon-ht.flag-icon-squared {
  background-image: url(/static/media/ht.d2c21946.svg);
}
.flag-icon-hu {
  background-image: url(/static/media/hu.966f4933.svg);
}
.flag-icon-hu.flag-icon-squared {
  background-image: url(/static/media/hu.ac606833.svg);
}
.flag-icon-id {
  background-image: url(/static/media/id.f400e736.svg);
}
.flag-icon-id.flag-icon-squared {
  background-image: url(/static/media/id.e8823835.svg);
}
.flag-icon-ie {
  background-image: url(/static/media/ie.1c12635a.svg);
}
.flag-icon-ie.flag-icon-squared {
  background-image: url(/static/media/ie.110efbf8.svg);
}
.flag-icon-il {
  background-image: url(/static/media/il.51984728.svg);
}
.flag-icon-il.flag-icon-squared {
  background-image: url(/static/media/il.5de4d676.svg);
}
.flag-icon-im {
  background-image: url(/static/media/im.324fc467.svg);
}
.flag-icon-im.flag-icon-squared {
  background-image: url(/static/media/im.de2c825c.svg);
}
.flag-icon-in {
  background-image: url(/static/media/in.fd8e9111.svg);
}
.flag-icon-in.flag-icon-squared {
  background-image: url(/static/media/in.7ff299bf.svg);
}
.flag-icon-io {
  background-image: url(/static/media/io.d0fbb654.svg);
}
.flag-icon-io.flag-icon-squared {
  background-image: url(/static/media/io.17e7b804.svg);
}
.flag-icon-iq {
  background-image: url(/static/media/iq.77828901.svg);
}
.flag-icon-iq.flag-icon-squared {
  background-image: url(/static/media/iq.fb484ddc.svg);
}
.flag-icon-ir {
  background-image: url(/static/media/ir.15222e51.svg);
}
.flag-icon-ir.flag-icon-squared {
  background-image: url(/static/media/ir.c7f485eb.svg);
}
.flag-icon-is {
  background-image: url(/static/media/is.64bcaf97.svg);
}
.flag-icon-is.flag-icon-squared {
  background-image: url(/static/media/is.cc33fe26.svg);
}
.flag-icon-it {
  background-image: url(/static/media/it.1d72a5de.svg);
}
.flag-icon-it.flag-icon-squared {
  background-image: url(/static/media/it.5dd499d6.svg);
}
.flag-icon-je {
  background-image: url(/static/media/je.cbd1a798.svg);
}
.flag-icon-je.flag-icon-squared {
  background-image: url(/static/media/je.aa9f2724.svg);
}
.flag-icon-jm {
  background-image: url(/static/media/jm.7c660277.svg);
}
.flag-icon-jm.flag-icon-squared {
  background-image: url(/static/media/jm.a5de6786.svg);
}
.flag-icon-jo {
  background-image: url(/static/media/jo.414c8c7b.svg);
}
.flag-icon-jo.flag-icon-squared {
  background-image: url(/static/media/jo.fec99700.svg);
}
.flag-icon-jp {
  background-image: url(/static/media/jp.7a959dca.svg);
}
.flag-icon-jp.flag-icon-squared {
  background-image: url(/static/media/jp.cf7f0543.svg);
}
.flag-icon-ke {
  background-image: url(/static/media/ke.3db68a66.svg);
}
.flag-icon-ke.flag-icon-squared {
  background-image: url(/static/media/ke.56262ac0.svg);
}
.flag-icon-kg {
  background-image: url(/static/media/kg.2c2ad39a.svg);
}
.flag-icon-kg.flag-icon-squared {
  background-image: url(/static/media/kg.b38f7389.svg);
}
.flag-icon-kh {
  background-image: url(/static/media/kh.a1737437.svg);
}
.flag-icon-kh.flag-icon-squared {
  background-image: url(/static/media/kh.684bc3d2.svg);
}
.flag-icon-ki {
  background-image: url(/static/media/ki.161cfe03.svg);
}
.flag-icon-ki.flag-icon-squared {
  background-image: url(/static/media/ki.a02a4304.svg);
}
.flag-icon-km {
  background-image: url(/static/media/km.900d4c39.svg);
}
.flag-icon-km.flag-icon-squared {
  background-image: url(/static/media/km.86e906fc.svg);
}
.flag-icon-kn {
  background-image: url(/static/media/kn.28139a32.svg);
}
.flag-icon-kn.flag-icon-squared {
  background-image: url(/static/media/kn.59d27824.svg);
}
.flag-icon-kp {
  background-image: url(/static/media/kp.7120a619.svg);
}
.flag-icon-kp.flag-icon-squared {
  background-image: url(/static/media/kp.35e33aa7.svg);
}
.flag-icon-kr {
  background-image: url(/static/media/kr.91bdd05c.svg);
}
.flag-icon-kr.flag-icon-squared {
  background-image: url(/static/media/kr.840ab53b.svg);
}
.flag-icon-kw {
  background-image: url(/static/media/kw.31648aa5.svg);
}
.flag-icon-kw.flag-icon-squared {
  background-image: url(/static/media/kw.c244f6de.svg);
}
.flag-icon-ky {
  background-image: url(/static/media/ky.c45a6056.svg);
}
.flag-icon-ky.flag-icon-squared {
  background-image: url(/static/media/ky.1b4e0948.svg);
}
.flag-icon-kz {
  background-image: url(/static/media/kz.f9c692a8.svg);
}
.flag-icon-kz.flag-icon-squared {
  background-image: url(/static/media/kz.173d1029.svg);
}
.flag-icon-la {
  background-image: url(/static/media/la.da34e481.svg);
}
.flag-icon-la.flag-icon-squared {
  background-image: url(/static/media/la.e0ec1b4e.svg);
}
.flag-icon-lb {
  background-image: url(/static/media/lb.a3b4e030.svg);
}
.flag-icon-lb.flag-icon-squared {
  background-image: url(/static/media/lb.19d9a98d.svg);
}
.flag-icon-lc {
  background-image: url(/static/media/lc.940e9ba9.svg);
}
.flag-icon-lc.flag-icon-squared {
  background-image: url(/static/media/lc.25f4a746.svg);
}
.flag-icon-li {
  background-image: url(/static/media/li.0f6fda67.svg);
}
.flag-icon-li.flag-icon-squared {
  background-image: url(/static/media/li.c2a0388c.svg);
}
.flag-icon-lk {
  background-image: url(/static/media/lk.9018cbe9.svg);
}
.flag-icon-lk.flag-icon-squared {
  background-image: url(/static/media/lk.03c20243.svg);
}
.flag-icon-lr {
  background-image: url(/static/media/lr.babb668c.svg);
}
.flag-icon-lr.flag-icon-squared {
  background-image: url(/static/media/lr.0cfda4d7.svg);
}
.flag-icon-ls {
  background-image: url(/static/media/ls.4da8ca4f.svg);
}
.flag-icon-ls.flag-icon-squared {
  background-image: url(/static/media/ls.4e82463d.svg);
}
.flag-icon-lt {
  background-image: url(/static/media/lt.7e7110b6.svg);
}
.flag-icon-lt.flag-icon-squared {
  background-image: url(/static/media/lt.39f9399a.svg);
}
.flag-icon-lu {
  background-image: url(/static/media/lu.7f70b02c.svg);
}
.flag-icon-lu.flag-icon-squared {
  background-image: url(/static/media/lu.0d040ab2.svg);
}
.flag-icon-lv {
  background-image: url(/static/media/lv.0b4e6e1a.svg);
}
.flag-icon-lv.flag-icon-squared {
  background-image: url(/static/media/lv.eeafeb0f.svg);
}
.flag-icon-ly {
  background-image: url(/static/media/ly.2fbe5cc1.svg);
}
.flag-icon-ly.flag-icon-squared {
  background-image: url(/static/media/ly.01216c35.svg);
}
.flag-icon-ma {
  background-image: url(/static/media/ma.a2e6a76e.svg);
}
.flag-icon-ma.flag-icon-squared {
  background-image: url(/static/media/ma.7fe34c1e.svg);
}
.flag-icon-mc {
  background-image: url(/static/media/mc.acbf04f7.svg);
}
.flag-icon-mc.flag-icon-squared {
  background-image: url(/static/media/mc.58583480.svg);
}
.flag-icon-md {
  background-image: url(/static/media/md.d4e707da.svg);
}
.flag-icon-md.flag-icon-squared {
  background-image: url(/static/media/md.d692cd26.svg);
}
.flag-icon-me {
  background-image: url(/static/media/me.144e25db.svg);
}
.flag-icon-me.flag-icon-squared {
  background-image: url(/static/media/me.af7398f3.svg);
}
.flag-icon-mf {
  background-image: url(/static/media/mf.16878d20.svg);
}
.flag-icon-mf.flag-icon-squared {
  background-image: url(/static/media/mf.df26146e.svg);
}
.flag-icon-mg {
  background-image: url(/static/media/mg.f2d3fca1.svg);
}
.flag-icon-mg.flag-icon-squared {
  background-image: url(/static/media/mg.7c225464.svg);
}
.flag-icon-mh {
  background-image: url(/static/media/mh.63433c4c.svg);
}
.flag-icon-mh.flag-icon-squared {
  background-image: url(/static/media/mh.6a803acb.svg);
}
.flag-icon-mk {
  background-image: url(/static/media/mk.b96b8a63.svg);
}
.flag-icon-mk.flag-icon-squared {
  background-image: url(/static/media/mk.aa4bb76f.svg);
}
.flag-icon-ml {
  background-image: url(/static/media/ml.1dd7a9cc.svg);
}
.flag-icon-ml.flag-icon-squared {
  background-image: url(/static/media/ml.e264a626.svg);
}
.flag-icon-mm {
  background-image: url(/static/media/mm.1d208cb9.svg);
}
.flag-icon-mm.flag-icon-squared {
  background-image: url(/static/media/mm.7ba1a816.svg);
}
.flag-icon-mn {
  background-image: url(/static/media/mn.3dabfbbe.svg);
}
.flag-icon-mn.flag-icon-squared {
  background-image: url(/static/media/mn.31220459.svg);
}
.flag-icon-mo {
  background-image: url(/static/media/mo.904c6c48.svg);
}
.flag-icon-mo.flag-icon-squared {
  background-image: url(/static/media/mo.144efd00.svg);
}
.flag-icon-mp {
  background-image: url(/static/media/mp.4192a32b.svg);
}
.flag-icon-mp.flag-icon-squared {
  background-image: url(/static/media/mp.999a98e3.svg);
}
.flag-icon-mq {
  background-image: url(/static/media/mq.75f2db2f.svg);
}
.flag-icon-mq.flag-icon-squared {
  background-image: url(/static/media/mq.b1582df6.svg);
}
.flag-icon-mr {
  background-image: url(/static/media/mr.4610ac53.svg);
}
.flag-icon-mr.flag-icon-squared {
  background-image: url(/static/media/mr.d8751050.svg);
}
.flag-icon-ms {
  background-image: url(/static/media/ms.e38f8b78.svg);
}
.flag-icon-ms.flag-icon-squared {
  background-image: url(/static/media/ms.9e869fee.svg);
}
.flag-icon-mt {
  background-image: url(/static/media/mt.f5c28c0a.svg);
}
.flag-icon-mt.flag-icon-squared {
  background-image: url(/static/media/mt.485b92ba.svg);
}
.flag-icon-mu {
  background-image: url(/static/media/mu.46514c80.svg);
}
.flag-icon-mu.flag-icon-squared {
  background-image: url(/static/media/mu.b111c44a.svg);
}
.flag-icon-mv {
  background-image: url(/static/media/mv.44a3b21f.svg);
}
.flag-icon-mv.flag-icon-squared {
  background-image: url(/static/media/mv.8ea7a65e.svg);
}
.flag-icon-mw {
  background-image: url(/static/media/mw.dc4b1545.svg);
}
.flag-icon-mw.flag-icon-squared {
  background-image: url(/static/media/mw.5799d8d2.svg);
}
.flag-icon-mx {
  background-image: url(/static/media/mx.d326007c.svg);
}
.flag-icon-mx.flag-icon-squared {
  background-image: url(/static/media/mx.784b4934.svg);
}
.flag-icon-my {
  background-image: url(/static/media/my.9a1a93aa.svg);
}
.flag-icon-my.flag-icon-squared {
  background-image: url(/static/media/my.8bfd547b.svg);
}
.flag-icon-mz {
  background-image: url(/static/media/mz.660a207e.svg);
}
.flag-icon-mz.flag-icon-squared {
  background-image: url(/static/media/mz.ada07465.svg);
}
.flag-icon-na {
  background-image: url(/static/media/na.d0cf7369.svg);
}
.flag-icon-na.flag-icon-squared {
  background-image: url(/static/media/na.f5877d6e.svg);
}
.flag-icon-nc {
  background-image: url(/static/media/nc.8aca8cf1.svg);
}
.flag-icon-nc.flag-icon-squared {
  background-image: url(/static/media/nc.85447bfb.svg);
}
.flag-icon-ne {
  background-image: url(/static/media/ne.5c109026.svg);
}
.flag-icon-ne.flag-icon-squared {
  background-image: url(/static/media/ne.052dc664.svg);
}
.flag-icon-nf {
  background-image: url(/static/media/nf.cc20348d.svg);
}
.flag-icon-nf.flag-icon-squared {
  background-image: url(/static/media/nf.69e6c425.svg);
}
.flag-icon-ng {
  background-image: url(/static/media/ng.9eea84ef.svg);
}
.flag-icon-ng.flag-icon-squared {
  background-image: url(/static/media/ng.b9197e69.svg);
}
.flag-icon-ni {
  background-image: url(/static/media/ni.0de728cd.svg);
}
.flag-icon-ni.flag-icon-squared {
  background-image: url(/static/media/ni.66627f1f.svg);
}
.flag-icon-nl {
  background-image: url(/static/media/nl.f685765a.svg);
}
.flag-icon-nl.flag-icon-squared {
  background-image: url(/static/media/nl.09f3280d.svg);
}
.flag-icon-no {
  background-image: url(/static/media/no.859a1356.svg);
}
.flag-icon-no.flag-icon-squared {
  background-image: url(/static/media/no.93a89ef2.svg);
}
.flag-icon-np {
  background-image: url(/static/media/np.3b6c910a.svg);
}
.flag-icon-np.flag-icon-squared {
  background-image: url(/static/media/np.e1a5b411.svg);
}
.flag-icon-nr {
  background-image: url(/static/media/nr.c3d9797e.svg);
}
.flag-icon-nr.flag-icon-squared {
  background-image: url(/static/media/nr.e6357f8e.svg);
}
.flag-icon-nu {
  background-image: url(/static/media/nu.ea0da15f.svg);
}
.flag-icon-nu.flag-icon-squared {
  background-image: url(/static/media/nu.20d62fc1.svg);
}
.flag-icon-nz {
  background-image: url(/static/media/nz.dd987077.svg);
}
.flag-icon-nz.flag-icon-squared {
  background-image: url(/static/media/nz.8d51d87f.svg);
}
.flag-icon-om {
  background-image: url(/static/media/om.3c3ce4b6.svg);
}
.flag-icon-om.flag-icon-squared {
  background-image: url(/static/media/om.083160ec.svg);
}
.flag-icon-pa {
  background-image: url(/static/media/pa.b6f6c58d.svg);
}
.flag-icon-pa.flag-icon-squared {
  background-image: url(/static/media/pa.7b97403f.svg);
}
.flag-icon-pe {
  background-image: url(/static/media/pe.9336bb9f.svg);
}
.flag-icon-pe.flag-icon-squared {
  background-image: url(/static/media/pe.c0d3dc98.svg);
}
.flag-icon-pf {
  background-image: url(/static/media/pf.02ac196d.svg);
}
.flag-icon-pf.flag-icon-squared {
  background-image: url(/static/media/pf.b51ab54c.svg);
}
.flag-icon-pg {
  background-image: url(/static/media/pg.35097449.svg);
}
.flag-icon-pg.flag-icon-squared {
  background-image: url(/static/media/pg.63d984fd.svg);
}
.flag-icon-ph {
  background-image: url(/static/media/ph.43421e89.svg);
}
.flag-icon-ph.flag-icon-squared {
  background-image: url(/static/media/ph.a5cb5e63.svg);
}
.flag-icon-pk {
  background-image: url(/static/media/pk.8a893a89.svg);
}
.flag-icon-pk.flag-icon-squared {
  background-image: url(/static/media/pk.f0eea274.svg);
}
.flag-icon-pl {
  background-image: url(/static/media/pl.f7adaa94.svg);
}
.flag-icon-pl.flag-icon-squared {
  background-image: url(/static/media/pl.8dae9656.svg);
}
.flag-icon-pm {
  background-image: url(/static/media/pm.961638db.svg);
}
.flag-icon-pm.flag-icon-squared {
  background-image: url(/static/media/pm.15c6521b.svg);
}
.flag-icon-pn {
  background-image: url(/static/media/pn.1191bd4a.svg);
}
.flag-icon-pn.flag-icon-squared {
  background-image: url(/static/media/pn.90f828d4.svg);
}
.flag-icon-pr {
  background-image: url(/static/media/pr.22cf04d4.svg);
}
.flag-icon-pr.flag-icon-squared {
  background-image: url(/static/media/pr.0e5e97b9.svg);
}
.flag-icon-ps {
  background-image: url(/static/media/ps.9603fcc3.svg);
}
.flag-icon-ps.flag-icon-squared {
  background-image: url(/static/media/ps.b7495d8b.svg);
}
.flag-icon-pt {
  background-image: url(/static/media/pt.a36b3d87.svg);
}
.flag-icon-pt.flag-icon-squared {
  background-image: url(/static/media/pt.23adb9fe.svg);
}
.flag-icon-pw {
  background-image: url(/static/media/pw.34533125.svg);
}
.flag-icon-pw.flag-icon-squared {
  background-image: url(/static/media/pw.2084daff.svg);
}
.flag-icon-py {
  background-image: url(/static/media/py.d3e57b21.svg);
}
.flag-icon-py.flag-icon-squared {
  background-image: url(/static/media/py.e16a905e.svg);
}
.flag-icon-qa {
  background-image: url(/static/media/qa.9dfdc1ae.svg);
}
.flag-icon-qa.flag-icon-squared {
  background-image: url(/static/media/qa.33716533.svg);
}
.flag-icon-re {
  background-image: url(/static/media/re.ba6ff1f7.svg);
}
.flag-icon-re.flag-icon-squared {
  background-image: url(/static/media/re.36610173.svg);
}
.flag-icon-ro {
  background-image: url(/static/media/ro.e9130a28.svg);
}
.flag-icon-ro.flag-icon-squared {
  background-image: url(/static/media/ro.18358326.svg);
}
.flag-icon-rs {
  background-image: url(/static/media/rs.21a07404.svg);
}
.flag-icon-rs.flag-icon-squared {
  background-image: url(/static/media/rs.43b012ca.svg);
}
.flag-icon-ru {
  background-image: url(/static/media/ru.ab61f31e.svg);
}
.flag-icon-ru.flag-icon-squared {
  background-image: url(/static/media/ru.a36db5ee.svg);
}
.flag-icon-rw {
  background-image: url(/static/media/rw.e28b5d13.svg);
}
.flag-icon-rw.flag-icon-squared {
  background-image: url(/static/media/rw.240b3961.svg);
}
.flag-icon-sa {
  background-image: url(/static/media/sa.bdc3a5dc.svg);
}
.flag-icon-sa.flag-icon-squared {
  background-image: url(/static/media/sa.4dfb01ca.svg);
}
.flag-icon-sb {
  background-image: url(/static/media/sb.30f71378.svg);
}
.flag-icon-sb.flag-icon-squared {
  background-image: url(/static/media/sb.72fc0629.svg);
}
.flag-icon-sc {
  background-image: url(/static/media/sc.cc70eda9.svg);
}
.flag-icon-sc.flag-icon-squared {
  background-image: url(/static/media/sc.a6054a5f.svg);
}
.flag-icon-sd {
  background-image: url(/static/media/sd.a07e8d7a.svg);
}
.flag-icon-sd.flag-icon-squared {
  background-image: url(/static/media/sd.30cf87d0.svg);
}
.flag-icon-se {
  background-image: url(/static/media/se.20eb11c7.svg);
}
.flag-icon-se.flag-icon-squared {
  background-image: url(/static/media/se.452d4e21.svg);
}
.flag-icon-sg {
  background-image: url(/static/media/sg.b8d34582.svg);
}
.flag-icon-sg.flag-icon-squared {
  background-image: url(/static/media/sg.b7c7bc4d.svg);
}
.flag-icon-sh {
  background-image: url(/static/media/sh.e7ff4324.svg);
}
.flag-icon-sh.flag-icon-squared {
  background-image: url(/static/media/sh.6c9f2c00.svg);
}
.flag-icon-si {
  background-image: url(/static/media/si.a3605343.svg);
}
.flag-icon-si.flag-icon-squared {
  background-image: url(/static/media/si.52f4acef.svg);
}
.flag-icon-sj {
  background-image: url(/static/media/sj.5b6dddaf.svg);
}
.flag-icon-sj.flag-icon-squared {
  background-image: url(/static/media/sj.423c0247.svg);
}
.flag-icon-sk {
  background-image: url(/static/media/sk.6363e990.svg);
}
.flag-icon-sk.flag-icon-squared {
  background-image: url(/static/media/sk.80d4eb69.svg);
}
.flag-icon-sl {
  background-image: url(/static/media/sl.af0884d4.svg);
}
.flag-icon-sl.flag-icon-squared {
  background-image: url(/static/media/sl.7fac599c.svg);
}
.flag-icon-sm {
  background-image: url(/static/media/sm.ec8d432f.svg);
}
.flag-icon-sm.flag-icon-squared {
  background-image: url(/static/media/sm.9e1878a3.svg);
}
.flag-icon-sn {
  background-image: url(/static/media/sn.091e42c6.svg);
}
.flag-icon-sn.flag-icon-squared {
  background-image: url(/static/media/sn.eed2f853.svg);
}
.flag-icon-so {
  background-image: url(/static/media/so.a3293a25.svg);
}
.flag-icon-so.flag-icon-squared {
  background-image: url(/static/media/so.89f77f80.svg);
}
.flag-icon-sr {
  background-image: url(/static/media/sr.07e91c15.svg);
}
.flag-icon-sr.flag-icon-squared {
  background-image: url(/static/media/sr.5bd4f020.svg);
}
.flag-icon-ss {
  background-image: url(/static/media/ss.9a15dd43.svg);
}
.flag-icon-ss.flag-icon-squared {
  background-image: url(/static/media/ss.ee171740.svg);
}
.flag-icon-st {
  background-image: url(/static/media/st.12444111.svg);
}
.flag-icon-st.flag-icon-squared {
  background-image: url(/static/media/st.dafba84c.svg);
}
.flag-icon-sv {
  background-image: url(/static/media/sv.2e44b8d3.svg);
}
.flag-icon-sv.flag-icon-squared {
  background-image: url(/static/media/sv.b880c913.svg);
}
.flag-icon-sx {
  background-image: url(/static/media/sx.63ad9051.svg);
}
.flag-icon-sx.flag-icon-squared {
  background-image: url(/static/media/sx.592afe48.svg);
}
.flag-icon-sy {
  background-image: url(/static/media/sy.36ca8a42.svg);
}
.flag-icon-sy.flag-icon-squared {
  background-image: url(/static/media/sy.766197b3.svg);
}
.flag-icon-sz {
  background-image: url(/static/media/sz.f71239c6.svg);
}
.flag-icon-sz.flag-icon-squared {
  background-image: url(/static/media/sz.d266727d.svg);
}
.flag-icon-tc {
  background-image: url(/static/media/tc.60d0fb0f.svg);
}
.flag-icon-tc.flag-icon-squared {
  background-image: url(/static/media/tc.1529a2c1.svg);
}
.flag-icon-td {
  background-image: url(/static/media/td.e092133d.svg);
}
.flag-icon-td.flag-icon-squared {
  background-image: url(/static/media/td.d2ea13f1.svg);
}
.flag-icon-tf {
  background-image: url(/static/media/tf.b444aed9.svg);
}
.flag-icon-tf.flag-icon-squared {
  background-image: url(/static/media/tf.d82ec644.svg);
}
.flag-icon-tg {
  background-image: url(/static/media/tg.19ba5076.svg);
}
.flag-icon-tg.flag-icon-squared {
  background-image: url(/static/media/tg.53ea4f1f.svg);
}
.flag-icon-th {
  background-image: url(/static/media/th.bcc03a3c.svg);
}
.flag-icon-th.flag-icon-squared {
  background-image: url(/static/media/th.18046547.svg);
}
.flag-icon-tj {
  background-image: url(/static/media/tj.71c18cc6.svg);
}
.flag-icon-tj.flag-icon-squared {
  background-image: url(/static/media/tj.4891f0a0.svg);
}
.flag-icon-tk {
  background-image: url(/static/media/tk.5a5f2901.svg);
}
.flag-icon-tk.flag-icon-squared {
  background-image: url(/static/media/tk.a19184cd.svg);
}
.flag-icon-tl {
  background-image: url(/static/media/tl.51693b24.svg);
}
.flag-icon-tl.flag-icon-squared {
  background-image: url(/static/media/tl.2f04cd3c.svg);
}
.flag-icon-tm {
  background-image: url(/static/media/tm.179f40d6.svg);
}
.flag-icon-tm.flag-icon-squared {
  background-image: url(/static/media/tm.8e43ad9e.svg);
}
.flag-icon-tn {
  background-image: url(/static/media/tn.aad164fd.svg);
}
.flag-icon-tn.flag-icon-squared {
  background-image: url(/static/media/tn.cefdbbdf.svg);
}
.flag-icon-to {
  background-image: url(/static/media/to.cccdba4c.svg);
}
.flag-icon-to.flag-icon-squared {
  background-image: url(/static/media/to.ed0e1091.svg);
}
.flag-icon-tr {
  background-image: url(/static/media/tr.4dd4b60c.svg);
}
.flag-icon-tr.flag-icon-squared {
  background-image: url(/static/media/tr.63e4c16c.svg);
}
.flag-icon-tt {
  background-image: url(/static/media/tt.db362840.svg);
}
.flag-icon-tt.flag-icon-squared {
  background-image: url(/static/media/tt.cd3e7c0c.svg);
}
.flag-icon-tv {
  background-image: url(/static/media/tv.170f9453.svg);
}
.flag-icon-tv.flag-icon-squared {
  background-image: url(/static/media/tv.94a912bd.svg);
}
.flag-icon-tw {
  background-image: url(/static/media/tw.a4b47fff.svg);
}
.flag-icon-tw.flag-icon-squared {
  background-image: url(/static/media/tw.bf719cfa.svg);
}
.flag-icon-tz {
  background-image: url(/static/media/tz.4cba6f35.svg);
}
.flag-icon-tz.flag-icon-squared {
  background-image: url(/static/media/tz.d19a8ee9.svg);
}
.flag-icon-ua {
  background-image: url(/static/media/ua.f120e566.svg);
}
.flag-icon-ua.flag-icon-squared {
  background-image: url(/static/media/ua.133532e7.svg);
}
.flag-icon-ug {
  background-image: url(/static/media/ug.23515412.svg);
}
.flag-icon-ug.flag-icon-squared {
  background-image: url(/static/media/ug.1f14e988.svg);
}
.flag-icon-um {
  background-image: url(/static/media/um.c22e6f33.svg);
}
.flag-icon-um.flag-icon-squared {
  background-image: url(/static/media/um.030e7e0b.svg);
}
.flag-icon-us {
  background-image: url(/static/media/us.8886b28b.svg);
}
.flag-icon-us.flag-icon-squared {
  background-image: url(/static/media/us.7978889a.svg);
}
.flag-icon-uy {
  background-image: url(/static/media/uy.a4931745.svg);
}
.flag-icon-uy.flag-icon-squared {
  background-image: url(/static/media/uy.b0ab5d4c.svg);
}
.flag-icon-uz {
  background-image: url(/static/media/uz.7c1bc5a1.svg);
}
.flag-icon-uz.flag-icon-squared {
  background-image: url(/static/media/uz.a7acab2c.svg);
}
.flag-icon-va {
  background-image: url(/static/media/va.e06e2790.svg);
}
.flag-icon-va.flag-icon-squared {
  background-image: url(/static/media/va.0b05f974.svg);
}
.flag-icon-vc {
  background-image: url(/static/media/vc.0d135a5a.svg);
}
.flag-icon-vc.flag-icon-squared {
  background-image: url(/static/media/vc.604bb3c4.svg);
}
.flag-icon-ve {
  background-image: url(/static/media/ve.d358f342.svg);
}
.flag-icon-ve.flag-icon-squared {
  background-image: url(/static/media/ve.1f85fe92.svg);
}
.flag-icon-vg {
  background-image: url(/static/media/vg.ba8d3b37.svg);
}
.flag-icon-vg.flag-icon-squared {
  background-image: url(/static/media/vg.cd9d438b.svg);
}
.flag-icon-vi {
  background-image: url(/static/media/vi.8a74e2f6.svg);
}
.flag-icon-vi.flag-icon-squared {
  background-image: url(/static/media/vi.20039586.svg);
}
.flag-icon-vn {
  background-image: url(/static/media/vn.89c6f27e.svg);
}
.flag-icon-vn.flag-icon-squared {
  background-image: url(/static/media/vn.d8159b15.svg);
}
.flag-icon-vu {
  background-image: url(/static/media/vu.3d642e71.svg);
}
.flag-icon-vu.flag-icon-squared {
  background-image: url(/static/media/vu.f243acac.svg);
}
.flag-icon-wf {
  background-image: url(/static/media/wf.89479cef.svg);
}
.flag-icon-wf.flag-icon-squared {
  background-image: url(/static/media/wf.028cb146.svg);
}
.flag-icon-ws {
  background-image: url(/static/media/ws.096fc500.svg);
}
.flag-icon-ws.flag-icon-squared {
  background-image: url(/static/media/ws.9dcd85be.svg);
}
.flag-icon-ye {
  background-image: url(/static/media/ye.d4cc9017.svg);
}
.flag-icon-ye.flag-icon-squared {
  background-image: url(/static/media/ye.c7f96a73.svg);
}
.flag-icon-yt {
  background-image: url(/static/media/yt.50163b97.svg);
}
.flag-icon-yt.flag-icon-squared {
  background-image: url(/static/media/yt.38b534ef.svg);
}
.flag-icon-za {
  background-image: url(/static/media/za.e2da160e.svg);
}
.flag-icon-za.flag-icon-squared {
  background-image: url(/static/media/za.ae23c355.svg);
}
.flag-icon-zm {
  background-image: url(/static/media/zm.c748b3b4.svg);
}
.flag-icon-zm.flag-icon-squared {
  background-image: url(/static/media/zm.9a7407fb.svg);
}
.flag-icon-zw {
  background-image: url(/static/media/zw.cecaeba0.svg);
}
.flag-icon-zw.flag-icon-squared {
  background-image: url(/static/media/zw.883b97a7.svg);
}
.flag-icon-ac {
  background-image: url(/static/media/ac.5ef6126f.svg);
}
.flag-icon-ac.flag-icon-squared {
  background-image: url(/static/media/ac.76180a51.svg);
}
.flag-icon-cp {
  background-image: url(/static/media/cp.8a3956c6.svg);
}
.flag-icon-cp.flag-icon-squared {
  background-image: url(/static/media/cp.a9dec775.svg);
}
.flag-icon-dg {
  background-image: url(/static/media/dg.16df7a03.svg);
}
.flag-icon-dg.flag-icon-squared {
  background-image: url(/static/media/dg.73a393c8.svg);
}
.flag-icon-ea {
  background-image: url(/static/media/ea.9634cedd.svg);
}
.flag-icon-ea.flag-icon-squared {
  background-image: url(/static/media/ea.5c0daa3e.svg);
}
.flag-icon-es-ct {
  background-image: url(/static/media/es-ct.3eb3a35a.svg);
}
.flag-icon-es-ct.flag-icon-squared {
  background-image: url(/static/media/es-ct.e5d7fd1b.svg);
}
.flag-icon-es-ga {
  background-image: url(/static/media/es-ga.9fae3ca2.svg);
}
.flag-icon-es-ga.flag-icon-squared {
  background-image: url(/static/media/es-ga.8d5a55c0.svg);
}
.flag-icon-eu {
  background-image: url(/static/media/eu.510ca6da.svg);
}
.flag-icon-eu.flag-icon-squared {
  background-image: url(/static/media/eu.ab50940e.svg);
}
.flag-icon-gb-eng {
  background-image: url(/static/media/gb-eng.7caecb78.svg);
}
.flag-icon-gb-eng.flag-icon-squared {
  background-image: url(/static/media/gb-eng.a8779518.svg);
}
.flag-icon-gb-nir {
  background-image: url(/static/media/gb-nir.ed38443a.svg);
}
.flag-icon-gb-nir.flag-icon-squared {
  background-image: url(/static/media/gb-nir.d70b4354.svg);
}
.flag-icon-gb-sct {
  background-image: url(/static/media/gb-sct.ade55ed4.svg);
}
.flag-icon-gb-sct.flag-icon-squared {
  background-image: url(/static/media/gb-sct.9506ab93.svg);
}
.flag-icon-gb-wls {
  background-image: url(/static/media/gb-wls.94904119.svg);
}
.flag-icon-gb-wls.flag-icon-squared {
  background-image: url(/static/media/gb-wls.c488ecae.svg);
}
.flag-icon-ic {
  background-image: url(/static/media/ic.bdc9877c.svg);
}
.flag-icon-ic.flag-icon-squared {
  background-image: url(/static/media/ic.dc0d5bfc.svg);
}
.flag-icon-ta {
  background-image: url(/static/media/ta.a41464b7.svg);
}
.flag-icon-ta.flag-icon-squared {
  background-image: url(/static/media/ta.b2aa6270.svg);
}
.flag-icon-un {
  background-image: url(/static/media/un.5232b1ea.svg);
}
.flag-icon-un.flag-icon-squared {
  background-image: url(/static/media/un.19f1d0df.svg);
}
.flag-icon-xk {
  background-image: url(/static/media/xk.ba8a3499.svg);
}
.flag-icon-xk.flag-icon-squared {
  background-image: url(/static/media/xk.b9df535c.svg);
}
.flag-icon-xx {
  background-image: url(/static/media/xx.05e0208d.svg);
}
.flag-icon-xx.flag-icon-squared {
  background-image: url(/static/media/xx.06dd12d7.svg);
}

